.home {
  section {
    &:nth-of-type(2n+1) {
      background: #fff;
    }
    &:nth-of-type(2n) {
      background: #f7fafc;
    }
  }
}
