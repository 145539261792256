@import 'tailwind';
@import './variables.scss';

body {
  font-size: 1.1rem;
  background: #f5f5f5;
}

.container {
  max-width: 1260px !important;
}

h1,
h2 {
  span {
    color: $color-primary;
    font-weight: 600;
  }
}

p {
  span {
    display: none;
  }
}

.title-with-line {
  display: flex;
  flex-direction: row;
  font-weight: bold;
  margin-bottom: 15px;

  &:after {
    content: '';
    flex: 1 1;
    border-bottom: 1px solid currentColor;
    margin: auto;
    margin-left: 15px;
  }
}

.pattern-diagonal-lines-sm {
  background-image: repeating-linear-gradient(
    45deg,
    #e5e7eb 0,
    #e5e7eb 1px,
    transparent 0,
    transparent 50%
  );
  background-size: 10px 10px;
}

.card-group {
  display: flex;
  flex-flow: row wrap;
}
.card-group > .card2:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.card-group > .card2 {
  flex: 1 0 0%;
  margin-bottom: 0;
}

/* Remove Hyperlinks */
@media print {
  a:link {
      border-bottom: none !important;
      text-decoration: none !important;
  }
  a[href]:after {
      content: none !important;
  }

  .sidebar-mb {
    margin-bottom: 209px;
  }

  .main-mb {
    margin-bottom: 48px;
  }
}
